.dependants {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000000cc;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dependants__wrapper {
  background: #fff;
  padding: 25px;
  width: 800px;
  height: 60vh;
  display: flex;
  flex-direction: column;
}

.dependants__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}

.dependants__title {
  font-size: 22px;
}

.dependants__close {
  cursor: pointer;
}

.dependants__content {
  padding-top: 15px;
  padding-bottom: 15px;
  flex: 1;
  height: 100%;
  overflow-y: auto;
}

.dependants__dependencies {
  display: flex;
  justify-content: space-around;
  padding-top: 25px;
  flex-direction: column;
}

.dependants__option {
  border: 1px solid #ddd;
  padding: 10px;
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dependants__option img {
  width: 100px;
}

.dependants__dependant {
  border-bottom: 1px solid #ccc;
}

.dependants__dependant img {
  width: 100px;
}

.dependants__dependant .dependants__option {
  border: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.dependants__info {
  text-align: center;
  margin-bottom: 10px;
  color: indianred;
  font-weight: bold;
}

.dependants__option--title {
  font-size: 12px;
}

.dependants__option--price {
  text-align: right;
}

.dependants__footer {
  text-align: right;
  padding-top: 10px;
  border-top: 1px solid #ccc;
}

.dependants__footer .modelSelector__cleanbutton {
  display: inline-block;
}
